$(document).ready(function() {


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * teleport links
    // *
    // * @set outer parent element class: js-href-teleport-wrapper
    // * @set link (<a> tag) element class: js-href-teleport-link
    // * @set element to add the link to class: js-href-teleport
    // *
    // * This adds a link tag (<a>) to other elements within a wrapper
    // * links comes from a link. Example: add a link to h2, image etc. inside a teaser
    // *
    $(".js-href-teleport").each(function(){
        var $link = $(this).parents(".js-href-teleport-wrapper").find(".js-href-teleport-link"),
            href = $link.attr("href"),
            target = $link.attr("target") ? $link.attr("target") : '_self';

        if (href) {
            $(this).wrapInner('<a href="' + href + '" target="' + target + '"></a>');
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * parent clickable elements (excludes links inside)
    // *
    // * @set outer parent element class: js-click-item-parent
    // * @set link (<a> tag) element class: js-click-item-link
    // *
    // * This makes the whole element clickable and still
    // * makes other links inside clickable with their target
    // *
    $(".js-click-item-parent").delegate('a', 'click', function(e){
		var target = $(this).attr("target"),
			url = $(this).attr("href");

		if (target == "_blank") {
			window.open(url);
		}else {
			window.location = url;
		}
        return false;
    }).click(function(){
		var target = $(this).find("a.js-click-item-link").attr("target"),
			url = $(this).find("a.js-click-item-link").attr("href");

		if (target == "_blank") {
			window.open(url);
		}else {
			window.location = url;
		}
        return false;
    });​



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * Scroll-To
    // *
    // * Smooth-Scroll to targets on page
    // *
    $('a[href*="#"]:not([href="#"])').click(function() {
        if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
            if (target.length) {
                scrollTo(target);
            return false;
            }
        }
    });

    function scrollTo(element) {
        $('html, body').animate({
            scrollTop: element.offset().top - 100
        }, 1000);
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * animateIn
    // *
    // *
    var offset = 60; // Distance from Browserbottom where the animation should start

    function fadeInElements(){
        var viewPort = $(window).scrollTop() + $(window).height();

        $(".animateIn:visible").each(function(){
            var elementTop = $(this).offset().top;

            if ((elementTop + offset) <= viewPort) {
                var delay = $(this).data("animation-delay");
                $(this).css("transition-delay", delay + "s").addClass("animateIn--active");
            }
        });
    }

    $(window).scroll(function() {
        fadeInElements();
    });

    fadeInElements();


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * add target blank to external links
    // *
    // *
    $('a:not([data-targetblank=ignore])').each(function() {
        if(location.hostname === this.hostname || !this.hostname.length) {
            // ... do nothing?
        }else {
            $(this).attr('target','_blank');
        }
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * heroIntro
    // *
    // *
    var $heroIntro = $(".js-herointro");

    $(window).on("load resize scroll",function(e){
        var percent = ((100 - (($(window).scrollTop() * 100) / $heroIntro.height())* 1.3) / 100);
        $heroIntro.css("opacity", percent);

        if (percent <= 0.8) {
            $heroIntro.addClass("sticky");
        }else {
            $heroIntro.removeClass("sticky");
        }
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * sticky header
    // *
    // *
    $(window).on("load resize scroll",function(e){
		if($(window).scrollTop() >= ($(".js-herointro").height() - 250)) {
			$(".js-header").addClass("sticky");
		}else {
			$(".js-header").removeClass("sticky");
		}
	});



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * accordion
    // *
    // *
    $(".js-accordion-button").click(function(){
        $(this).parents(".js-accordion").toggleClass("active").find(".js-accordion-content").slideToggle();
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * priceRange
    // *
    // *
    $(".js-pricerange-input").on("input", function(e){
        var value = $(this).val(),
            min = $(this).data("min"),
            max = $(this).data("max"),
            percent = ((value - min) * 100) / (max - min);
        $(".js-pricerange-value").html(formatCurrency(value));
        $(".js-pricerange-bar").css("width", percent + "%");
    });

    function formatCurrency(value){
        let val = (value/1).toFixed(2).replace('.', ',');
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }

    $(".js-pricerange-input").trigger("input");




    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * accessCode
    // *
    // *
    $(".js-accesscode-input").on("input", function(e){
        var length = $(this).val().length,
            mask = '-'.repeat((6 - length));

        $(".js-accesscode-mask").html(mask);
    });







    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * timewall
    // *
    // *
    if ($(".js-timewall").length) {
        setTimeout(function(){
            window.location.reload(1);
        }, 10000);
    }


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * websocket Server
    // *
    // *
    if ($(".js-live").length) {
        var serverUrl = "wss://mqtt.happen-studio.de:8083/",
            id = $(".js-live").data("page-id"),
            stream = "stream" + id,
            chat = "chat" + id,
            clientStream = new Paho.MQTT.Client(serverUrl, '"' + randomNumber(9999) + '"'),
            clientChat = new Paho.MQTT.Client(serverUrl, '"' + randomNumber(9999) + '"');

        function randomNumber(max) {
            return Math.floor(Math.random() * max);
        }
    }


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * action Icons
    // *
    // *
    if ($(".js-live").length) {

        function onConnectStream(){
            clientStream.subscribe(stream);
        }

        clientStream.connect({
            onSuccess: onConnectStream
        });

        clientStream.onMessageArrived = function (message) {
            var icon = message.payloadString,
                left = randomNumber(100),
                random = randomNumber(99999);

            $(".js-iconbuttons-runway").append('<img class="active" id="' + random + '" src="/assets/templates/web/img/' + icon + '.png" style="left:' + left + '%">');

            setTimeout(function() {
                $("#" + random).remove();
            }, 2000);
        }

        $(".js-iconbuttons-icon").click(function(){
                // send a message
                var icon = $(this).data("action"),
                    message = new Paho.MQTT.Message(icon);

                message.destinationName = stream;

                clientStream.send(message);
             }
        );
    }





    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * chat
    // *
    // *
    $(".js-chat-button").click(function(){
        $(".js-chat").toggleClass("active");
    });

    //Auto-scroll
	function autoScrollChat() {
        var scrollHeight = $(".js-chat-list").prop('scrollHeight'); //Scroll height after the request
        //console.log(scrollHeight);
    	//$(".js-chat-list").animate({ scrollTop: newscrollHeight }, 0); //Autoscroll to bottom of div
        $(".js-chat-list").scrollTop(scrollHeight);
    }

    autoScrollChat();

    // close
    $(".js-chat-close").click(function(){
        $(".js-chat").removeClass("active");
    });


    // chat websocket
    if ($(".js-live").length) {

        function onConnectChat(){
            clientChat.subscribe(chat);
        }

        clientChat.connect({
            onSuccess: onConnectChat
        });

        clientChat.onMessageArrived = function (message) {
            var text = message.payloadString;

            $(".js-chat-list").append('<li class="chat__message">' + text + '</li>');

            autoScrollChat();
        }


        $(document).on('submit', '.js-chat-form', function(e){
            e.preventDefault();

            // send a message
            var text = $(this).find(".js-chat-input").val(),
                message = new Paho.MQTT.Message(text);

            message.destinationName = chat;

            clientChat.send(message);

            // clear message
            $(this).find(".js-chat-input").val('');
        });
    }


});
